import './bootstrap';

import InfiniteScroll from 'infinite-scroll';
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
document.addEventListener('DOMContentLoaded', () => {

    var swiper = new Swiper(".default-carousel", {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    document.querySelectorAll('.seo__opener').forEach(function (item) {
        item.addEventListener('click', function (e) {
            let node = e.target
            let siblingsArr = siblings(node, 'seo__text')
            siblingsArr.forEach(function (item2) {
                if (item2.classList.contains('--open')) {
                    window.scrollTo(
                        {top: document.querySelector(".seo").offsetTop - (window.innerHeight / 3 * 2), behavior: 'smooth'});
                }
                item2.classList.toggle('--open')
            })
            node.style.display = 'none'
            siblingsArr = siblings(node, 'seo__opener')
            if (siblingsArr && siblingsArr[0])
                siblingsArr[0].style.display = 'block'
        })
    })

    if(document.querySelector(".post_wrapper") && document.querySelector('.read_more')){
        let page = 2;
        let infScroll = new InfiniteScroll( document.querySelector(".post_wrapper"), {
            // options
            path: function() {
                const link = new URL(document.querySelector('.read_more').getAttribute('data-ajax-link'));
                if(link.searchParams.size===0)
                    return document.querySelector('.read_more').getAttribute('data-ajax-link')+"?page="+page;
                else{
                    return document.querySelector('.read_more').getAttribute('data-ajax-link')+"&page="+page;
                }
            },
            append: '.post_item',
            history: false,
            button: '.read_more',
            scrollThreshold: false,
            status:".loader"
        });
        infScroll.on( 'last', function( body, path ) {
            document.querySelector('.loader').style.display =  'none'
        });

        infScroll.on( 'append', function( body, path, items, response ) {
            imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'))
            page++;
        });

    }
    if(document.querySelector(".load_more_3_posts") && document.querySelector('.load_more_3_posts')) {
        let page = 2;
        let infScroll = new InfiniteScroll(document.querySelector(".post_wrapper"), {
            // options
            path: function () {
                return document.querySelector('.load_more_3_posts').getAttribute('data-ajax-link') + "?page=" + page;
            },
            append: '.post_item',
            history: false,
            button: '.load_more_3_posts',
            scrollThreshold: false,
            status: ".loader"
        });
        infScroll.on('last', function (body, path) {
            document.querySelector('.loader').style.display = 'none'
        });

        infScroll.on('append', function (body, path, items, response) {
            imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'))
            page++;
        });
    }





    function siblings(node, className) {
        var sibs = [];
        let elem = node.parentNode.firstChild;
        do {
            if (elem.nodeType === 3) continue; // text node
            if ((!className || (elem.classList && elem.classList.contains(className))) && !elem.isEqualNode(node)) sibs.push(elem);
        } while (elem = elem.nextSibling)
        return sibs;

    }
    imageLazyLoading(document.querySelectorAll('img[src="#"][loading=lazy]'))


    // checker
    const useItemChecker = (els, onClickOutside) => {
        const checkBodyClick = (e) => {
            let currentEl = e.target;

            while (currentEl) {
                if (els.includes(currentEl)) break;
                currentEl = currentEl.parentNode
            }

            if (!currentEl) {
                onClickOutside()
                removeBodyChecker()
            }
        }
        function setBodyChecker  ()  {
            document.documentElement.addEventListener('click', checkBodyClick)
        }
        function removeBodyChecker ()  {
            document.documentElement.removeEventListener('click', checkBodyClick)
        }
        return {setBodyChecker, removeBodyChecker}
    }

    // change theme by clicking a button
    const toggleDarkThemeBtn = document.querySelectorAll('.toggle-dark-theme');

    const enableDarkMode = () => {
        localStorage.theme = 'dark'

        document.documentElement.classList.add('dark');
    };

    const disableDarkMode = () => {
        localStorage.theme = 'light'

        document.documentElement.classList.remove('dark');
    };

    if (toggleDarkThemeBtn) {
        toggleDarkThemeBtn.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault()

                if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                    disableDarkMode();
                } else {
                    enableDarkMode();
                }
            });
        })
    }

    // vh
    function vh(percent) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (percent * h) / 100;
    }

    // cookies
    const cookies = document.querySelector('.cookies')
    const cookiesClose = document.querySelector('.js-cookies-close')

    if (cookies && cookiesClose) {
        if (localStorage.cookiesAlert === 'false' || (!('cookiesAlert' in localStorage))) {
            cookies.classList.add('is--active')
        } else {
            cookies.classList.remove('is--active')
        }

        cookiesClose.addEventListener('click', (event) => {
            event.preventDefault()

            if (cookies.classList.contains('is--active')) {
                cookies.classList.remove('is--active')

                localStorage.cookiesAlert = 'true'
            }
        })
    }

    // hamburger
    const menu = document.getElementById('menu')
    const hamburgerTrigger = document.getElementById('hamburger-trigger')

    if (menu && hamburgerTrigger) {
        hamburgerTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (menu.classList.contains('is--active')) {
                hamburgerTrigger.classList.remove('is--active')
                menu.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')

                menu.style.removeProperty('top')
                menu.style.removeProperty('max-height')
                menu.style.removeProperty('height')
            } else {
                hamburgerTrigger.classList.add('is--active')
                menu.classList.add('is--active')
                document.body.classList.add('scroll-disabled')

                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    menu.style.top = Number(56) + offsetTop + 'px'
                    menu.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    menu.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                }

                if (search && searchTrigger) {
                    if (search.classList.contains('is--active')) {
                        searchTrigger.classList.remove('is--active')
                        search.classList.remove('is--active')
                    }
                }
            }
        })

        menu.addEventListener('click', (event) => {
            if (event.target.classList.contains('menu') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (menu.classList.contains('is--active')) {
                    hamburgerTrigger.classList.remove('is--active')
                    menu.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')

                    menu.style.removeProperty('top')
                    menu.style.removeProperty('max-height')
                    menu.style.removeProperty('height')
                }
            }
        })
    }

    // search
    const search = document.getElementById('search')
    const searchTrigger = document.getElementById('search-trigger')

    if (search && searchTrigger) {
        searchTrigger.addEventListener('click', (event) => {
            event.preventDefault()

            if (search.classList.contains('is--active')) {
                searchTrigger.classList.remove('is--active')
                search.classList.remove('is--active')
                document.body.classList.remove('scroll-disabled')

                search.style.removeProperty('top')
                search.style.removeProperty('max-height')
                search.style.removeProperty('height')
            } else {
                searchTrigger.classList.add('is--active')
                search.classList.add('is--active')
                document.body.classList.add('scroll-disabled')

                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    search.style.top = Number(56) + offsetTop + 'px'
                    search.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    search.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                }

                if (menu && hamburgerTrigger) {
                    if (menu.classList.contains('is--active')) {
                        hamburgerTrigger.classList.remove('is--active')
                        menu.classList.remove('is--active')
                    }
                }
            }
        })

        search.addEventListener('click', (event) => {
            if (event.target.classList.contains('search') || event.target.classList.contains('container')) {
                event.preventDefault()

                if (search.classList.contains('is--active')) {
                    searchTrigger.classList.remove('is--active')
                    search.classList.remove('is--active')
                    document.body.classList.remove('scroll-disabled')

                    search.style.removeProperty('top')
                    search.style.removeProperty('max-height')
                    search.style.removeProperty('height')
                }
            }
        })
    }

    // like
    // const likeTrigger = document.querySelectorAll('.like-trigger')
    //
    // if (likeTrigger) {
    //     likeTrigger.forEach(item => {
    //         item.addEventListener('click', (event) => {
    //             event.preventDefault()
    //
    //             if (item.classList.contains('is--active')) {
    //                 item.classList.remove('is--active')
    //             } else {
    //                 item.classList.add('is--active')
    //             }
    //         })
    //     })
    // }

    // share
    const shareTrigger = document.querySelectorAll('.share-trigger')

    if (searchTrigger) {
        shareTrigger.forEach(item => {
            const close = () => {
                const parent = item.parentNode

                parent.classList.remove('is--active')
            }
            const itemChecker = useItemChecker([item.parentNode], close)

            item.addEventListener('click', (event) => {
                event.preventDefault()

                const parent = item.parentNode

                if (parent.classList.contains('is--active')) {
                    close()
                } else {
                    parent.classList.add('is--active')
                    itemChecker.setBodyChecker()
                }
            })
        })
    }

    // scroll
    let prevScrollpos = window.pageYOffset

    function headerScrollUp() {
        if (window.pageYOffset >= (document.querySelector('.header').getBoundingClientRect().top) && window.pageYOffset <= (document.querySelector('.header').getBoundingClientRect().height) && getComputedStyle(document.querySelector('.header-fixed-inner')).position == 'relative') {
            document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
            document.querySelector('.header-fixed-inner').style.position = 'relative'
        } else {
            if (window.pageYOffset <= (document.querySelector('.header-top').getBoundingClientRect().height)) {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
                document.querySelector('.header-fixed-inner').style.position = 'relative'
            } else {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
                document.querySelector('.header-fixed-inner').style.position = 'fixed'
            }
        }
    }

    function headerScrollDown() {
        if (window.pageYOffset >= (document.querySelector('.header').getBoundingClientRect().height)) {
            if (getComputedStyle(document.querySelector('.header-fixed-inner')).position == 'relative') {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, -100%, 0px)'

                setTimeout(() => {
                    document.querySelector('.header-fixed-inner').style.position = 'fixed'
                }, 150)
            } else {
                document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, -100%, 0px)'
            }
        } else {
            document.querySelector('.header-fixed-inner').style.transform = 'translate3d(0px, 0px, 0px)'
            document.querySelector('.header-fixed-inner').style.position = 'relative'
        }
    }

    window.addEventListener('scroll', () => {
        let currentScrollPos = window.pageYOffset;

        if (prevScrollpos > currentScrollPos || prevScrollpos <= 0) { // if up
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header-fixed-inner')) {
                    headerScrollUp()
                }
            }
        } else { // if down
            if (!document.body.classList.contains('scroll-disabled')) {
                if (document.querySelector('.header-fixed-inner')) {
                    headerScrollDown()
                }
            }
        }

        prevScrollpos = currentScrollPos;
    })

    window.addEventListener('resize', () => {
        if (menu) {
            if (menu.classList.contains('is--active')) {
                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    menu.style.top = Number(56) + offsetTop + 'px'
                    menu.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    menu.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                } else {
                    menu.style.removeProperty('top')
                    menu.style.removeProperty('max-height')
                    menu.style.removeProperty('height')
                }
            }
        }

        if (search) {
            if (search.classList.contains('is--active')) {
                if (window.innerWidth >= 1280) {
                    let offsetTop = (document.querySelector('.header-fixed-inner').getBoundingClientRect().top >= 0) ? document.querySelector('.header-fixed-inner').getBoundingClientRect().top : 0
                    search.style.top = Number(56) + offsetTop + 'px'
                    search.style.maxHeight = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                    search.style.height = vh(100) - offsetTop - document.querySelector('.header-fixed-inner').getBoundingClientRect().height + 'px'
                } else {
                    search.style.removeProperty('top')
                    search.style.removeProperty('max-height')
                    search.style.removeProperty('height')
                }
            }
        }
    })
});
function imageLazyLoading(arr)
{
    if ("IntersectionObserver" in window) {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }


        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const lazyImg = entry.target
                    makeImgLazy(lazyImg)
                    observer.unobserve(lazyImg)
                }
            })
        }, options)

        arr.forEach(i => {
            observer.observe(i)
        })

    }else{
        arr.forEach(i => {
            makeImgLazy(i)
        })
    }
}

function makeImgLazy(lazyImg)
{

    let picture = lazyImg.parentElement
    if(picture && picture.tagName && picture.tagName === "PICTURE"){
        let sources =  picture.querySelectorAll("source");
        sources.forEach(function (source, index){
            if(sources[index].dataset.srcset)
                sources[index].srcset = sources[index].dataset.srcset
        })
    }

    lazyImg.src = lazyImg.dataset.src
}
